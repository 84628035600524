import React from 'react';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const LoadingIndicator: React.FC = () => {
    const antIcon = <Loading3QuartersOutlined style={{ fontSize: 30 }} spin />;
    return (
        <Spin indicator={antIcon} style={{ display: 'block', textAlign: 'center', marginTop: 30 }} />
    );
};

export default LoadingIndicator;
