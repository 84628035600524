import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { MenuItemResponse } from '../types/generated/taxovia-rfid';
import { getAllMenuRovs } from '../api/api';
import { QUERY_KEYS } from './queryKeys';
import { isLoggedIn } from './authUtils';

export function useMenu(): UseQueryResult<MenuItemResponse> {
  return useQuery({
    queryKey: [QUERY_KEYS.MENU],
    queryFn: getAllMenuRovs,
    staleTime: Infinity,
    enabled: isLoggedIn(),
  });
}
