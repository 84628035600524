import React from 'react';
import { Button, Result } from 'antd';

const NotFound: React.FC = () => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Požadovaná informácia sa nenašla."
            extra={<Button type="primary">Späť</Button>}
        />
    );
};

export default NotFound;
