import {
  deleteOrder,
  forgotPassword,
  login,
  order,
  pairRfidCard,
  resetPassword,
  signup,
  unpairRfidCard,
  updateIdentity,
  updateUserOrganization,
  validateRegistration,
} from '../api/api';

import {
  deleteOrderMutation,
  forgotPasswordMutation,
  loginMutation,
  orderMutation,
  organisationMutation,
  pairRFIDMutation,
  resetPasswordMutation,
  signUpMutation,
  unpairRFIDMutation,
  userMutation,
  validateRegistrationMutation,
} from './TaxoviaMutationTypes';
import { TaxoviaActionType } from './TaxoviaActionType';
import { TaxoviaFunction } from './TaxoviaTypes';

const {
  LOGIN,
  SIGNUP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  PAIR_RFID,
  UNPAIR_RFID,
  UPDATE_USER_ORGANIZATION,
  UPDATE_USER,
  VALIDATE_USER,
  ORDER_MENU,
  DELETE_ORDER,
} = TaxoviaActionType;

export const mutationTypeToFunctionMap: Record<TaxoviaActionType, TaxoviaFunction> = {
  [LOGIN]: (data: loginMutation) => login(data.payload.login),
  [SIGNUP]: (data: signUpMutation) => signup(data.payload.signup),
  [VALIDATE_USER]: (data: validateRegistrationMutation) => validateRegistration(data.payload.request),
  [FORGOT_PASSWORD]: (data: forgotPasswordMutation) => forgotPassword(data.payload.request),
  [RESET_PASSWORD]: (data: resetPasswordMutation) => resetPassword(data.payload.request),
  [PAIR_RFID]: (data: pairRFIDMutation) => pairRfidCard(data.payload.request),
  [UNPAIR_RFID]: (data: unpairRFIDMutation) => unpairRfidCard(data.payload.code),
  [UPDATE_USER_ORGANIZATION]: (data: organisationMutation) => updateUserOrganization(data.payload.request),
  [UPDATE_USER]: (data: userMutation) => updateIdentity(data.payload.request),
  [ORDER_MENU]: (data: orderMutation) => order(data.payload.request),
  [DELETE_ORDER]: (data: deleteOrderMutation) => deleteOrder(data.payload.id),
};
