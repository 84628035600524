import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { useCurrentUser } from '../hooks/useCurrentUser';

const { Footer } = Layout;

const AppFooter: React.FC = () => {
  const { data: currentUser } = useCurrentUser();
  return (
    <Footer>
      {currentUser && (
        <div>
          <Link to="/invoice">Sumár</Link> | <Link to={`/settings/${currentUser?.username}`}>Nastavenia</Link>
        </div>
      )}
      <div>{'© 2024 Prolog K (' + process.env.REACT_APP_VERSION + ')'}</div>
    </Footer>
  );
};

export default AppFooter;
