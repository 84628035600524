import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ConfigResponse } from '../types/generated/taxovia-rfid';
import { getConfig } from '../api/api';
import { QUERY_KEYS } from './queryKeys';

export function useConfig(): UseQueryResult<ConfigResponse> {
  return useQuery({
    queryKey: [QUERY_KEYS.CONFIG],
    queryFn: getConfig,
    staleTime: Infinity,
    enabled: true,
  });
}
