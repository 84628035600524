import {
  ApiResponse,
  ConfigResponse,
  ForgotPasswordRequest,
  JwtAuthenticationResponse,
  LoginRequest,
  MenuItemResponse,
  OrderReport,
  OrderRequest,
  OrganizationRequest,
  OrganizationResponse,
  ResetPasswordRequest,
  RfidCardRequest,
  RfidCardResponse,
  ShutdownDto,
  SignUpRequest,
  UserIdentityAvailability,
  UserIdentityRequest,
  UserProfile,
  UserSummary,
  VerificationRequest,
} from '../types/generated/taxovia-rfid';
import { taxoviaClient } from '../axios/taxoviaClient';
import { X_API_KEY } from '../constants/constants';

export async function loadCurrentUser(): Promise<UserSummary> {
  return await taxoviaClient.get('/user/me/full');
}

export async function updateIdentity(request: UserIdentityRequest): Promise<ApiResponse> {
  return await taxoviaClient.post('/user/me/full', request);
}

export async function getConfig(): Promise<ConfigResponse> {
  return await taxoviaClient.get('/config');
}

export async function loadUserProfile(): Promise<UserProfile> {
  return await taxoviaClient.get('/users/profile');
}

export async function loadOrderReport(): Promise<OrderReport> {
  return await taxoviaClient.get('/order/list');
}

export async function checkShutdown(): Promise<ShutdownDto> {
  return await taxoviaClient.get('/shutdown');
}

export async function login(login: LoginRequest): Promise<JwtAuthenticationResponse> {
  return await taxoviaClient.post('/auth/signin', login);
}

export async function signup(request: SignUpRequest): Promise<ApiResponse> {
  return await taxoviaClient.post('/auth/signup', request);
}

export async function validateRegistration(request: VerificationRequest): Promise<ApiResponse> {
  return await taxoviaClient.post('/registration-confirm', request);
}

export async function forgotPassword(request: ForgotPasswordRequest): Promise<ApiResponse> {
  return await taxoviaClient.post('/pwd-recovery', request);
}

export async function resetPassword(request: ResetPasswordRequest): Promise<ApiResponse> {
  return await taxoviaClient.put('/pwd-recovery', request);
}

export async function loadRfidCardList(): Promise<RfidCardResponse> {
  return await taxoviaClient.get('/rfid');
}

export async function pairRfidCard(request: RfidCardRequest): Promise<ApiResponse> {
  return await taxoviaClient.post('/rfid', request);
}

export async function unpairRfidCard(code: string): Promise<ApiResponse> {
  return await taxoviaClient.delete('/rfid?code=' + code);
}

export async function getUserOrganization(): Promise<OrganizationResponse> {
  return await taxoviaClient.get('/user/organization');
}

export async function updateUserOrganization(request: OrganizationRequest): Promise<ApiResponse> {
  return await taxoviaClient.post('/user/organization', request);
}

export async function checkUsernameAvailability(username: string): Promise<UserIdentityAvailability> {
  return await taxoviaClient.get(`/user/checkUsernameAvailability?username=${username}`, {
    headers: {
      'X-API-KEY': X_API_KEY,
    },
  });
}

export async function checkEmailAvailability(email: string): Promise<UserIdentityAvailability> {
  return await taxoviaClient.get(`/user/checkEmailAvailability?email=${email}`, {
    headers: {
      'X-API-KEY': X_API_KEY,
    },
  });
}

export async function getAllMenuRovs(): Promise<MenuItemResponse> {
  return await taxoviaClient.get('/rov-menu/order');
}

export async function order(request: OrderRequest): Promise<ApiResponse> {
  return await taxoviaClient.post('/order', request);
}

export async function deleteOrder(id: number): Promise<ApiResponse> {
  return await taxoviaClient.delete(`/order?id=${id}`);
}
