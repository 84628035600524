import Order from './Order';
import React from 'react';
import { MenuItemDto } from '../types/generated/taxovia-rfid';

interface ButtonOrderProps {
  record: MenuItemDto;
}

const ButtonOrder: React.FC<ButtonOrderProps> = ({ record }) => {
  return <Order menuRovId={record?.id}  />;
};

export default ButtonOrder;
