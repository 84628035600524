import React from 'react';
import { Table, TableProps } from 'antd';
import './Price.css';
import { PriceDto } from '../types/generated/taxovia-rfid';
import { useConfig } from '../hooks/useConfig';

interface PriceProps {
  priceList: PriceDto[];
}

const Price: React.FC<PriceProps> = ({ priceList }) => {
  const { data } = useConfig();
  const useSmallOrder = data?.config?.useSmallOrder;

  const columns: TableProps<PriceDto>['columns'] = [
    {
      title: 'ROV',
      dataIndex: 'rov',
      key: 'rov',
    },
    {
      title: useSmallOrder ? 'Veľká porcia' : 'Cena',
      dataIndex: 'price',
      key: 'price',
      render: (text, record) => parseFloat(String(Math.round(record.price! * 100) / 100)).toFixed(2) + ' €',
    },
  ];

  if (useSmallOrder) {
    columns.push({
      title: 'Malá porcia',
      dataIndex: 'priceSmall',
      key: 'priceSmall',
      render: (text, record) => parseFloat(String(Math.round(record.priceSmall! * 100) / 100)).toFixed(2) + ' €',
    });
  }

  return (
    <div className="price-menu-container">
      <h2 className="page-title">Cenník</h2>
      <Table
        rowKey={(record) => '' + record.id}
        dataSource={priceList}
        pagination={false}
        columns={columns}
        locale={{ emptyText: 'Nie je platný cenník!' }}
      />
    </div>
  );
};

export default Price;
