export const QUERY_KEYS = {
    CONFIG: ['config'],
    USER: ['user'],
    ORDER_REPORT: ['order_report'],
    PROFILE: ['user-profile'],
    SHUTDOWN: ['shutdown'],
    RFID: ['rfid'],
    ORGANIZATION: ['organization'],
    USERNAME_AVAILABLE: ['username-available'],
    EMAIL_AVAILABLE: ['email-available'],
    SIGNUP: ['signup'],
    VALIDATE_REGISTRATION: ['validate-registration'],
    MENU: ['menu'],
    MUTATION: ['taxovia-mutate']
} as const;
