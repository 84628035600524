import React from 'react';
import { FolderAddOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { useConfig } from '../hooks/useConfig';
import { useTaxoviaMutation } from '../hooks/useTaxoviaMutation';
import { ApiResponse } from '../types/generated/taxovia-rfid';
import { TaxoviaActionType } from '../hooks/TaxoviaActionType';

interface OrderProps {
  menuRovId: number | undefined;
}

const Order: React.FC<OrderProps> = (props) => {
  const { data } = useConfig();
  const useSmallOrder = data?.config?.useSmallOrder;
  const { mutate: taxoviaMutate } = useTaxoviaMutation({
    onSuccess: (response: ApiResponse) => {
      notification.success({
        message: 'Taxovia App',
        description: response.message,
      });
    },
    onError: (error) => {
      notification.error({
        message: 'Taxovia App',
        description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!',
      });
    },
  });

  const handlerOrder = (event: React.MouseEvent<HTMLElement>, quantity: number) => {
    event.preventDefault();
    const request = {
      menuItemId: props.menuRovId,
      quantity: quantity,
    };

    taxoviaMutate({
      type: TaxoviaActionType.ORDER_MENU,
      payload: { request },
    });
  };

  const orderText = useSmallOrder ? 'Veľká porcia' : 'Objednať';

  return (
    <>
      <Button type="default" onClick={(e) => handlerOrder(e, 1)}>
        <FolderAddOutlined />
        {orderText}
      </Button>
      {useSmallOrder && (
        <Button type="default" onClick={(e) => handlerOrder(e, 0.5)}>
          <FolderAddOutlined />
          Malá porcia
        </Button>
      )}
    </>
  );
};

export default Order;
