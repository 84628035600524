import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { UserSummary } from '../types/generated/taxovia-rfid';
import { loadCurrentUser } from '../api/api';
import { QUERY_KEYS } from './queryKeys';
import { isLoggedIn } from './authUtils';

export function useCurrentUser(): UseQueryResult<UserSummary> {
  return useQuery({
    queryKey: [QUERY_KEYS.USER],
    queryFn: loadCurrentUser,
    staleTime: Infinity,
    enabled: isLoggedIn(),
  });
}
