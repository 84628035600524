import React, { MouseEvent } from 'react';
import { QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, notification, Popconfirm } from 'antd';
import { useTaxoviaMutation } from '../hooks/useTaxoviaMutation';
import { ApiResponse } from '../types/generated/taxovia-rfid';
import { TaxoviaActionType } from '../hooks/TaxoviaActionType';

interface DeleteOrderProps {
  id: number | undefined;
}

const DeleteOrder: React.FC<DeleteOrderProps> = ({ id }) => {
  const { mutate: taxoviaMutate } = useTaxoviaMutation({
    onSuccess: (response: ApiResponse) => {
      notification.success({
        message: 'Taxovia App',
        description: response.message,
      });
    },
    onError: () => {
      notification.error({
        message: 'Taxovia App',
        description: 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!',
      });
    },
  });

  const handlerOrder = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();

    if (id) {
      taxoviaMutate({
        type: TaxoviaActionType.DELETE_ORDER,
        payload: { id },
      });
    }
  };

  return (
    <Popconfirm
      title="Určite mám zrušiť objednávku？"
      onConfirm={handlerOrder}
      okText="Áno, zrušiť"
      cancelText="Nie"
      icon={<QuestionCircleOutlined />}
    >
      <Button type="default" danger>
        <WarningOutlined /> Zrušiť
      </Button>
    </Popconfirm>
  );
};

export default DeleteOrder;
