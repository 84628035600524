/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { Suspense, LazyExoticComponent } from 'react';
import { Skeleton } from 'antd';

const Setting: LazyExoticComponent<React.FC<any>> = React.lazy(() => import(/* webpackChunkName: "setting" */ './Setting'));

const SettingLoadable: React.FC<any> = (props) => (
    <Suspense fallback={<Skeleton />}>
        <Setting {...props} />
    </Suspense>
);

export default SettingLoadable;
