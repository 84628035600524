import moment from 'moment';
import { MenuItemDto } from '../types/generated/taxovia-rfid';

export const menuClassName = (record: MenuItemDto): string => {
  return record.order !== null
    ? 'menu-ordered menu-' +
        record?.rov?.toLocaleLowerCase() +
        ' menu-itemid-' +
        record?.itemId?.toLocaleLowerCase() +
        ' menu-day-' +
        moment(record.date).day()
    : 'menu-item menu-' +
        record?.rov?.toLocaleLowerCase() +
        ' menu-itemid-' +
        record?.itemId?.toLocaleLowerCase() +
        ' menu-day-' +
        moment(record.date).day();
};
