import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ValidateRegistration.css';
import { Button, Form, notification } from 'antd';
import queryString from 'qs';
import { useTaxoviaMutation } from '../../hooks/useTaxoviaMutation';
import { ApiResponse, VerificationRequest } from '../../types/generated/taxovia-rfid';
import { TaxoviaActionType } from '../../hooks/TaxoviaActionType';

const { Item: FormItem } = Form;

interface ValidationValues {
  token?: string;
}

const ValidationRegistration: React.FC = () => {
  const location = useLocation();
  const values: ValidationValues = queryString.parse(location.search.slice(1));

  return (
    <div className="validation-registration-container">
      <h2 className="page-title">Overenie registrácie</h2>
      <div className="validation-registration-content">
        <ValidationRegistrationForm token={values.token || ''} />
      </div>
    </div>
  );
};

interface ValidationRegistrationFormProps {
  token: string;
}

const ValidationRegistrationForm: React.FC<ValidationRegistrationFormProps> = ({ token }) => {
  const navigate = useNavigate();
  const { mutate: taxoviaMutate } = useTaxoviaMutation({
    onSuccess: (response: ApiResponse) => {
      notification.success({
        message: 'Taxovia App',
        description: response.message,
      });
      navigate('/');
    },
    onError: () => {
      notification.error({
        message: 'Taxovia App',
        description: 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!',
      });
    },
  });

  const handleSubmit = () => {
    const request: VerificationRequest = { token };
    taxoviaMutate({
      type: TaxoviaActionType.VALIDATE_USER,
      payload: { request },
    });
  };

  const [form] = Form.useForm();

  return (
    <Form form={form} onFinish={handleSubmit} className="validation-registration-form">
      <FormItem>
        <p>Kliknutím na tlačítko overíte Vašu registráciu</p>
        <Button type="primary" htmlType="submit" size="large" className="validation-registration-form-button">
          Overenie registrácie
        </Button>
      </FormItem>
    </Form>
  );
};

export default ValidationRegistration;
