import React from 'react';
import ButtonDeleteOrder from './ButtonDeleteOrder';
import ButtonOrder from './ButtonOrder';
import { MenuItemDto } from '../types/generated/taxovia-rfid';

interface ButtonMenuProps {
  record: MenuItemDto;
}

const ButtonMenu: React.FC<ButtonMenuProps> = ({ record }) => {
  if (record.itemId === '-') {
    return null;
  }

  if (record.order === null) {
    return <ButtonOrder record={record} />;
  }

  if (record.order?.menu?.active) {
    return <ButtonDeleteOrder record={record} />;
  }

  return null;
};

export default ButtonMenu;
