import React from 'react';
import { Alert, AlertProps, FloatButton } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import './MenuRovList.css';
import MenuRovFooter from './MenuRovFooter';
import NoMenuRovFound from './NoMenuRovFound';
import Price from '../price/Price';
import { Default, Mobile } from '../util/Helpers';
import MenuRovListComponent from './MenuRovListComponent';
import MenuRovListTable from './MenuRovListTable';
import Title from '../util/Title';
import Shutdown from '../shutdown/Shutdown';
import { useMenu } from '../hooks/useMenu';
import { isLoggedIn } from '../hooks/authUtils';
import LoadingIndicator from '../common/LoadingIndicator';
import { AxiosError } from 'axios';

interface MenuRovParam {
  handleLogout: () => void;
}

const MenuRovListWrapper: React.FC<MenuRovParam> = ({ handleLogout }) => {
  const { data: menu, isLoading, isError, error } = useMenu();

  if (isError && (error as AxiosError)?.status === 403) {
    handleLogout();
  }

  if (isLoading && isLoggedIn()) {
    return <LoadingIndicator />;
  }

  const message = menu?.message as AlertProps;

  return (
    <div className="menu-rov-container">
      <Shutdown>
        {!isLoggedIn() ? (
          <NoMenuRovFound />
        ) : (
          <div>
            <Title pageTitle="Menu ROV" />
            {message && (
              <div className="taxovia-alert">
                <Alert type={message.type} message={message.message} description={message.description} showIcon />
              </div>
            )}
            <PageHeader title="Jedálny lístok" subTitle="Objednávky, cenník" />
            <FloatButton.BackTop />
            <Mobile>
              <MenuRovListComponent dataSource={menu?.menu ?? []} />
            </Mobile>
            <Default>
              <MenuRovListTable dataSource={menu?.menu ?? []} />
            </Default>
            <MenuRovFooter />
            <Price priceList={menu?.price ?? []} />
          </div>
        )}
      </Shutdown>
    </div>
  );
};

export default MenuRovListWrapper;
