import React from 'react';
import { Table } from 'antd';
import { menuClassName } from './MenuRovUtil';
import moment from 'moment';
import ButtonMenu from './ButtonMenu';
import 'moment/locale/sk';
import { MenuItemDto } from '../types/generated/taxovia-rfid';

interface MenuRovListTableProps {
  dataSource: MenuItemDto[];
}

const MenuRovListTable: React.FC<MenuRovListTableProps> = (props) => {

  const columns = [
    {
      title: 'Dátum',
      dataIndex: 'date',
      key: 'date',
      className: 'menu-date',
      render: (text: string) => moment(text).locale('sk').format('dddd DD.MM.YYYY'),
    },
    {
      title: 'ROV',
      dataIndex: 'rov',
      key: 'rov',
      className: 'menu-rov',
    },
    {
      title: 'Výber',
      dataIndex: 'itemId',
      key: 'itemId',
      className: 'menu-itemid',
    },
    {
      title: 'Menu',
      dataIndex: 'description',
      key: 'description',
      className: 'menu-description',
    },
    {
      title: 'Akcia',
      key: 'action',
      className: 'menu-action',
      render: (record: MenuItemDto) => <ButtonMenu record={record} />,
    },
  ];

  return (
    <Table<MenuItemDto>
      rowKey={(record) => record?.id ?? ''}
      dataSource={props.dataSource}
      pagination={{ pageSize: 25, showLessItems: true }}
      columns={columns}
      locale={{ emptyText: 'Nie je k dispozícii jedálny lístok' }}
      rowClassName={(record) => menuClassName(record)}
    />
  );
};

export default MenuRovListTable;
