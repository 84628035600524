import React from 'react';
import { Skeleton } from 'antd';

const OrderAndServeList = React.lazy(() => import(/* webpackChunkName: "orderandserver" */ './OrderAndServeWrapper'));

const OrderAndServeListLoadable: React.FC = () => (
  <React.Suspense fallback={<Skeleton />}>
    <OrderAndServeList />
  </React.Suspense>
);

export default OrderAndServeListLoadable;
