import React from 'react';
import Responsive from 'react-responsive';

interface ResponsiveProps {
  children: React.ReactNode;
}

// Typovanie pre Mobile a Default komponenty
export const Mobile: React.FC<ResponsiveProps> = (props) => (
    <Responsive {...props} maxWidth={767} />
);

export const Default: React.FC<ResponsiveProps> = (props) => (
    <Responsive {...props} minWidth={768} />
);

// Typovanie pre formatDate
export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  const monthNames = [
    'Január',
    'Február',
    'Marec',
    'Apríl',
    'Máj',
    'Jún',
    'Júl',
    'August',
    'September',
    'Október',
    'November',
    'December',
  ];

  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return monthNames[monthIndex] + ' ' + year;
};
