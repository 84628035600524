import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app/App';
import register from './registerServiceWorker';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

const queryClient = new QueryClient();
ReactDOM.render(
  <Router>
      <QueryClientProvider client={queryClient}>
          <App />
      </QueryClientProvider>
  </Router>,
  document.getElementById('root')
);

register();
