import React from 'react';
import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN } from '../constants/constants';

import Login from '../user/login/Login';
import Signup from '../user/signup/SignUpLoadable';
import AppHeader from '../common/AppHeader';
import NotFound from '../common/NotFound';

import { Layout, notification } from 'antd';
import MenuRovList from '../menurov/MenuRovListWrapper';
import ForgotPassword from '../user/resetpwd/ForgotPasswordLoadable';
import ResetPassword from '../user/resetpwd/ResetPasswordLoadable';
import PrivateRoute from '../common/PrivateRoute';
import Setting from '../user/setting/SettingLoadable';
import ValidationRegistration from '../user/validation/ValidationRegistration';
import OrderAndServeList from '../invoice/OrderAndServeListLoadable';
import AppFooter from '../common/AppFooter';
import { isLoggedIn } from '../hooks/authUtils';

const { Content, Footer } = Layout;

const App: React.FC = () => {
  const isAuthenticated = isLoggedIn();
  const navigate = useNavigate();

  notification.config({
    placement: 'topRight',
    top: 70,
    duration: 3,
  });

  const handleLogout = () => {
    const redirectTo = '/';
    const notificationType = 'success';
    const description = 'Odhlásili sme Ťa.';
    localStorage.removeItem(ACCESS_TOKEN);
    navigate(redirectTo);

    notification[notificationType]({
      message: 'TaxoviaClientApp',
      description: description,
    });

    window.location.reload();
  };

  const handleFinish = (message: string) => {
    notification.success({
      message: 'TaxoviaClientApp',
      description: message,
    });
    navigate('/');
  };

  return (
    <Layout className="app-container">
      <AppHeader onLogout={handleLogout} />

      <Content className="app-content">
        <div className="app-container">
          <Routes>
            <Route path="/" element={<MenuRovList handleLogout={handleLogout} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword onFinish={handleFinish} />} />
            <Route path="/reset" element={<ResetPassword onFinish={handleFinish} />} />
            <Route path="/registration-confirm" element={<ValidationRegistration />} />
            <Route path="/signup" element={<Signup />} />
            <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
              <Route path="/invoice" element={<OrderAndServeList />} />
              <Route path="/settings/:username" element={<Setting handleLogout={handleLogout} />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <AppFooter />
      </Footer>
    </Layout>
  );
};

export default App;
