import React, { Suspense } from 'react';

const ForgotPassword = React.lazy(() => import(/* webpackChunkName: "forgotpassword" */ './ForgotPassword'));

interface ForgotPasswordLoadableProps {
    onFinish: (message: string) => void;
}

const ForgotPasswordLoadable: React.FC<ForgotPasswordLoadableProps> = (props) => (
    <Suspense fallback={<div>Loading...</div>}>
        <ForgotPassword {...props} />
    </Suspense>
);

export default ForgotPasswordLoadable;
