import React from 'react';
import { Carousel } from 'antd';
import './NoMenuRovFound.css';
import Title from '../util/Title';

const NoMenuRovFound: React.FC = () => {
  return (
    <div className="no-menu-container">
      <Title siteTitle="Taxovia" pageTitle="Taxovia" />
      <Carousel autoplay autoplaySpeed={5000}>
        <div>
          <img
            src={'./assets/images/soup1.jpg'}
            alt="Tradičný slovenský guláš servírovaný v hlinenej miske na rustikálnom drevenom stole. Horúca polievka s kúskami mäsa a zeleniny sa parí a okolo misky sú roztrúsené jesenné listy, ktoré dotvárajú pokojný a útulný vzhľad scény. Svetlo jemne dopadá na stôl, čím vytvára teplú a príjemnú atmosféru, pripomínajúcu jesenné obdobie."
          />
        </div>
        <div>
          <img
            src={'./assets/images/palacinky.jpg'}
            alt="Tanier tradičných slovenských palaciniek (palacinky), zlatohnedých a ľahko chrumkavých na okrajoch, poliatych medom a ozdobených čerstvým ovocím, ako sú maliny, čučoriedky a ríbezle. Palacinky sú nasypané práškovým cukrom a lákajú na prvý pohľad svojou chutnou prezentáciou. Scéna je rustikálna, s teplým prírodným osvetlením, evokujúcim príjemnú a útulnú atmosféru."
          />
        </div>
        <div>
          <img
            src={'./assets/images/salat.jpg'}
            alt="Zdravý šalát servírovaný v hlinenej miske na rustikálnom drevenom stole. Šalát je čerstvý a farebný, obsahuje zmiešané listy šalátu, cherry paradajky, uhorky a posypaný je semienkami. Atmosféra je pokojná a príjemná, s teplým prirodzeným svetlom, ktoré dotvára útulný vzhľad prostredia."
          />
        </div>
        <div>
          <img
            src={'./assets/images/duck-leg.jpg'}
            alt="Tradičné slovenské jedlo servírované na hlinenom tanieri – chrumkavé kačacie stehno s dusenou kapustou, zemiakovou lokšou a varenými zemiakmi, podávané na rustikálnom drevenom stole v príjemnom a teplom prostredí."
          />
        </div>
      </Carousel>
      <div className="no-menu-rov-found">
        Návod na použitie nájdete na{' '}
        <a href={'https://navod.taxovia.eu'} target={'_blank'} rel="noopener noreferrer">
          navod.taxovia.eu
        </a>
      </div>
    </div>
  );
};

export default NoMenuRovFound;
