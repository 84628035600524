import axios from 'axios';
import { ACCESS_TOKEN } from '../constants/constants';

export const taxoviaClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

taxoviaClient.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(ACCESS_TOKEN);
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

taxoviaClient.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        return Promise.reject(error);
    }
);
