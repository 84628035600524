import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ShutdownDto } from '../types/generated/taxovia-rfid';
import { checkShutdown } from '../api/api';
import { QUERY_KEYS } from './queryKeys';

export function useShutdown(): UseQueryResult<ShutdownDto> {
  return useQuery({
    queryKey: [QUERY_KEYS.SHUTDOWN],
    queryFn: checkShutdown,
    staleTime: 5 * 60 * 1000,
    cacheTime: 15 * 60 * 1000,
    refetchInterval: 30 * 60 * 1000,
  });
}
