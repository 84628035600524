import React, { ReactNode } from 'react';
import { Result, Spin } from 'antd';
import './Shutdown.css';
import { useShutdown } from '../hooks/useShutdown';

interface ShutdownProps {
  children: ReactNode;
}

const Shutdown: React.FC<ShutdownProps> = ({ children }) => {
  const { data, isLoading } = useShutdown();

  if (isLoading) {
    return (
      <div className="shutdown-container">
        <Spin size="large" />
      </div>
    );
  }

  return data?.shutdown ? <Result status="warning" title="Prebieha údržba systému!" /> : <>{children}</>;
};

export default Shutdown;
