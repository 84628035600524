import React, { Suspense, LazyExoticComponent } from 'react';

const Signup: LazyExoticComponent<React.FC> = React.lazy(() => import(/* webpackChunkName: "signup" */ './Signup'));

const SignupLoadable: React.FC = (props) => (
    <Suspense fallback={<div>Loading...</div>}>
        <Signup {...props} />
    </Suspense>
);

export default SignupLoadable;
