import React from 'react';
import './Login.css';
import { Link, useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN } from '../../constants/constants';

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification } from 'antd';
import Title from '../../util/Title';
import { useTaxoviaMutation } from '../../hooks/useTaxoviaMutation';
import { TaxoviaActionType } from '../../hooks/TaxoviaActionType';
import { JwtAuthenticationResponse, LoginRequest } from '../../types/generated/taxovia-rfid';
import { TaxoviaReturnType } from '../../hooks/TaxoviaTypes';

const { Item: FormItem } = Form;

const Login: React.FC = () => {
  return (
    <div className="login-container">
      <Title pageTitle="Prihlásenie" />
      <h2 className="page-title">Prihlásenie</h2>
      <div className="login-content">
        <LoginForm />
      </div>
    </div>
  );
};

const LoginForm: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { mutate: taxoviaMutate } = useTaxoviaMutation({
    onSuccess: (response: TaxoviaReturnType) => {
      const jwtResponse = response as JwtAuthenticationResponse;
      if (jwtResponse?.accessToken) {
        localStorage.setItem(ACCESS_TOKEN, jwtResponse.accessToken);
        navigate('/');
      }
    },
    onError: () => {
      notification.error({
        message: 'Taxovia App',
        description: 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!',
      });
    },
  });

  const handleOnFinish = (values: LoginRequest) => {
    taxoviaMutate({
      type: TaxoviaActionType.LOGIN,
      payload: { login: values },
    });
  };

  return (
    <Form form={form} onFinish={handleOnFinish} className="login-form">
      <FormItem
        name="usernameOrEmail"
        rules={[{ required: true, message: 'Zadajte prihlasovacie meno, alebo email!' }]}
      >
        <Input
          prefix={<UserOutlined />}
          size="large"
          name="usernameOrEmail"
          placeholder="Prihlasovacie meno alebo email"
        />
      </FormItem>
      <FormItem name="password" rules={[{ required: true, message: 'Prosím zadajte heslo!' }]}>
        <Input prefix={<LockOutlined />} size="large" name="password" type="password" placeholder="Heslo" />
      </FormItem>
      <FormItem>
        <Button type="primary" htmlType="submit" size="large" className="login-form-button">
          Prihlásenie
        </Button>
        alebo <Link to="/signup">sa zaregistrujte teraz!</Link>
        <span className="forgot-pwd">
          <Link to="/forgot-password">Zabudnuté heslo</Link>
        </span>
      </FormItem>
    </Form>
  );
};

export default Login;
