import React, { useEffect } from 'react';

interface TitleProps {
    pageTitle: string;
    siteTitle?: string;
}

const getSiteTitle = (siteTitle?: string): string => siteTitle || process.env.REACT_APP_VERSION || '';

const Title: React.FC<TitleProps> = ({ pageTitle, siteTitle }) => {
    useEffect(() => {
        document.title = [pageTitle, getSiteTitle(siteTitle)].filter(Boolean).join(' | ');
    }, [pageTitle, siteTitle]);
    return null;
};

export default Title;
