import DeleteOrder from './DeleteOrder';
import React from 'react';
import { MenuItemDto } from '../types/generated/taxovia-rfid';

interface ButtonDeleteOrderProps {
  record: MenuItemDto;
}

const ButtonDeleteOrder: React.FC<ButtonDeleteOrderProps> = ({ record }) => {
  return <DeleteOrder id={record?.order?.id} />;
};

export default ButtonDeleteOrder;
