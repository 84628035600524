import React from 'react';
import './MenuRovFooter.css';
import {Collapse} from 'antd';

const MenuRovFooter: React.FC = () => {
    const items = [
        {
            key: '1',
            label: '*Označenie prítomnosti alergénov v potravinách',
            children: (
                <div className='menu-rov-footer'>
                    <ol>
                        <li>Obilniny obsahujúce lepok</li>
                        <li>Kôrovce</li>
                        <li>Vajcia</li>
                        <li>Ryby</li>
                        <li>Arašidy</li>
                        <li>Sójové zrná</li>
                        <li>Mlieko</li>
                        <li>Orechy</li>
                        <li>Zeler</li>
                        <li>Horčica</li>
                        <li>Sézamové semená</li>
                        <li>Oxid siričitý a siričňany</li>
                        <li>Vlčí bôb</li>
                        <li>Makkýše</li>
                    </ol>
                </div>
            ),
            className: 'menu-rov-footer-panel'
        }
    ];

    return (
        <Collapse bordered={false} items={items}/>
    );
};

export default MenuRovFooter;
