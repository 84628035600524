export enum TaxoviaActionType {
  LOGIN = 'login',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password',
  PAIR_RFID = 'pair-rfid',
  UNPAIR_RFID = 'unpair-rfid',
  UPDATE_USER_ORGANIZATION = 'update-user-organization',
  UPDATE_USER = 'update-user',
  SIGNUP = 'signup',
  VALIDATE_USER = 'validate-user',
  ORDER_MENU = 'order-menu',
  DELETE_ORDER = 'delete-order',
}
