import moment from 'moment';
import React from 'react';
import ButtonMenu from './ButtonMenu';
import { List } from 'antd';
import { menuClassName } from './MenuRovUtil';
import 'moment/locale/sk';
import { MenuItemDto } from '../types/generated/taxovia-rfid';

interface MenuRovListComponentProps {
  dataSource: MenuItemDto[];
}

const MenuRovListComponent: React.FC<MenuRovListComponentProps> = ({ dataSource }) => {
  const metaTitle = (item: MenuItemDto) => {
    return (
      <div>
        <div>{item.rov}</div>
        <div>{moment(item.date).locale('sk').format('dddd DD.MM.YYYY')}</div>
      </div>
    );
  };

  return (
    <List
      bordered
      dataSource={dataSource}
      pagination={{ pageSize: 10, showLessItems: true }}
      renderItem={(item) => (
        <List.Item key={item.id} className={menuClassName(item)}>
          <List.Item.Meta title={metaTitle(item)} description={item.description} />
          <div>
            <div className="list-menu-itemId">{item.itemId}</div>
            <ButtonMenu record={item} />
          </div>
        </List.Item>
      )}
    />
  );
};

export default MenuRovListComponent;
