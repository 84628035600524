import React, { Suspense } from 'react';

const ResetPassword = React.lazy(() => import(/* webpackChunkName: "resetpassword" */ './ResetPassword'));

interface ResetPasswordLoadableProps {
  onFinish: (message: string) => void;
}

const ResetPasswordLoadable: React.FC<ResetPasswordLoadableProps> = (props) => (
  <Suspense fallback={<div>Loading...</div>}>
    <ResetPassword {...props} />
  </Suspense>
);

export default ResetPasswordLoadable;
