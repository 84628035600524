import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from './queryKeys';
import { TaxoviaMutationData, TaxoviaReturnType } from './TaxoviaTypes';
import { mutationTypeToFunctionMap } from './mappers';
import { AxiosError } from 'axios';

type QueryOptions = Omit<
  UseMutationOptions<TaxoviaReturnType, Error, TaxoviaMutationData>,
  'mutationKey' | 'mutationFn'
>;

export function useTaxoviaMutation(
  options?: QueryOptions
): UseMutationResult<TaxoviaReturnType, unknown, TaxoviaMutationData> {
  const { onSuccess, ...rest } = options ?? {};
  const queryClient = useQueryClient();

  async function performMutation(data: TaxoviaMutationData): Promise<TaxoviaReturnType> {
    const mutationFn = mutationTypeToFunctionMap[data.type];
    if (!mutationFn) {
      throw new Error(`Unknown mutation type: ${data?.type}. Data: ${JSON.stringify(data)}`);
    }
    try {
      return await mutationFn(data);
    } catch (error) {
      throw new Error(`${error instanceof AxiosError ? error?.response?.data.message : ''}`);
    }
  }

  function handleMutationSuccess(
    data: TaxoviaReturnType,
    variables: TaxoviaMutationData,
    context: unknown,
    queryClient: ReturnType<typeof useQueryClient>,
    onSuccess?: (data: TaxoviaReturnType, variables: TaxoviaMutationData, context: unknown) => void
  ) {
    const queryKeysToInvalidate = [QUERY_KEYS.USER, QUERY_KEYS.SHUTDOWN, QUERY_KEYS.MENU, QUERY_KEYS.ORDER_REPORT];

    queryKeysToInvalidate.forEach((key) => {
      queryClient.invalidateQueries({ queryKey: [key] });
    });

    if (onSuccess) {
      onSuccess(data, variables, context);
    }
  }

  return useMutation({
    mutationKey: QUERY_KEYS.MUTATION,
    mutationFn: performMutation,
    onSuccess: (data: TaxoviaReturnType, variables: TaxoviaMutationData, context: unknown) =>
      handleMutationSuccess(data, variables, context, queryClient, onSuccess),
    onError: (error) => {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error('Unexpected error:', error);
      }
    },
    ...rest,
  } as UseMutationOptions<TaxoviaReturnType, Error, TaxoviaMutationData>);
}
